import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { SPACING } from 'Style/spacing';
import { UI_TEXT_TYPES } from 'Style/typography';
import { COLORS, SURFACE_COLORS, DIVIDER_COLORS } from 'Style/colors';

const RADIO_SIZE = 18;
const CHECKED_RADIO_SIZE = 12;

/**
 * For accessibility reasons, we should still use a radio input, and hide it
 * https://stackoverflow.com/questions/17541614/use-images-instead-of-radio-buttons
 */
const HiddenRadio = styled.input({
  position: 'absolute',
  opacity: '0',
  width: '0',
  height: '0',
});

const RadioButton = styled.div(
  {
    cursor: 'pointer',
    padding: SPACING.BASE,
    borderRadius: '2px',
  },
  (props) => {
    if (props.isFocused) {
      return {
        backgroundColor: SURFACE_COLORS.tertiary,
      };
    }
  },
);

const RadioIcon = styled.div(
  {
    height: `${RADIO_SIZE}px`,
    width: `${RADIO_SIZE}px`,
    borderRadius: `${RADIO_SIZE / 2}px`,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: DIVIDER_COLORS.secondary,
    marginRight: SPACING.SMALL,
    display: 'flex',
  },
  (props) => {
    if (props.checked) {
      return {
        borderColor: COLORS.themeReverse,
      };
    }
    if (props.focused) {
      return {
        borderColor: COLORS.themeReverse,
      };
    }
  },
);
const CheckedRadioIcon = styled.div({
  height: `${CHECKED_RADIO_SIZE}px`,
  width: `${CHECKED_RADIO_SIZE}px`,
  borderRadius: '6px',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: DIVIDER_COLORS.secondary,
  backgroundColor: COLORS.themeReverse,
  margin: 'auto',
});

const RadioLabelWrapper = styled.label({ cursor: 'pointer' });
const RadioLabel = styled.span(
  { ...UI_TEXT_TYPES.SUPPORTING_LARGE },
  (props) => {
    if (props.checked) {
      return { color: COLORS.themeReverse };
    }
  },
);

const RadioInputWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  marginBottom: SPACING.BASE,
});
class CustomRadio extends Component {
  constructor(props) {
    super(props);

    this.state = { isFocused: false };
    this.handleClick = this.handleClick.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleFocus() {
    this.setState({ isFocused: true }, this.props.onFocus);
  }

  handleBlur() {
    this.setState({ isFocused: false }, this.props.onBlur);
  }

  handleClick(e) {
    if (this.props.checked) {
      e.stopPropagation();
      e.preventDefault();
      this.props.onClear();
    }
  }

  render() {
    const { label, name, value, checked, className, disabled, onChange } =
      this.props;

    return (
      <RadioButton isFocused={this.state.isFocused} className={className}>
        <RadioLabelWrapper onClick={this.handleClick}>
          <RadioInputWrapper className=".custom-radio__input-wrapper">
            <HiddenRadio
              name={name}
              type="radio"
              value={value}
              checked={checked}
              onChange={(e) => !disabled && onChange(e)}
              onFocus={this.handleFocus}
              onBlur={this.handleBlur}
            />
            <RadioIcon checked={checked} focused={this.state.isFocused}>
              {checked && <CheckedRadioIcon />}
            </RadioIcon>
            <RadioLabel checked={checked}>{label}</RadioLabel>
          </RadioInputWrapper>
          {this.props.children}
        </RadioLabelWrapper>
      </RadioButton>
    );
  }
}

CustomRadio.propTypes = {
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
};

CustomRadio.defaultProps = {
  className: '',
  children: null,
  disabled: false,
  onFocus() {},
  onBlur() {},
  onClear() {},
};

export default CustomRadio;
